<template lang="pug">
	div(class="separator-container" v-bind:id="id")
		div(class="separator valign-wrapper")
			h4 {{ text }}
</template>

<style scoped>
.separator-container {
	background-color: #1d6765;
	color: #fff;
	width: 100%;
	padding: 30px 0;
}

.separator {

}

.separator h4 {
	text-align: center;
	text-transform: uppercase;
	margin: 0;
	width: 100%;
	font-weight: bold;
}

@media screen and (max-width: 540px) {
	.separator h4 {
		font-size: 1.5em;
	}
}
</style>

<script>
export default {
	props: ['text', 'id']
}
</script>